/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { change, Field } from 'redux-form';
import moment from 'moment/moment';
import { Translate as T } from 'react-redux-i18n';
import * as V from '../../lib/validations';
import { SHORT_DATE_FORMAT, DATE_RANGE_ERROR } from '../../constants';
import { Checkbox, Text } from '../fields';
import * as N from '../../lib/normalizations';

class EmploymentPeriod extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    employer: PropTypes.object.isRequired,
    lookbackYears: PropTypes.number.isRequired,
    presentLabel: PropTypes.string.isRequired,
    isLookbackRestricted: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      savedEndDate: '',
    };
  }

  isEndDateOutOfRange = (endDate, lookbackYears) => {
    return (
      moment(endDate, SHORT_DATE_FORMAT, true).isValid() &&
      moment(endDate, SHORT_DATE_FORMAT) <
        moment().subtract(lookbackYears, 'years')
    );
  };

  // This validation function checks if the given date is out of range,
  // but it only applies if the candidate follows the lookback path.
  dateOutOfRangeValidation = (lookbackYears, isLookbackRestricted) => {
    return value => {
      const isDateOutOfRange = this.isEndDateOutOfRange(value, lookbackYears);
      return isDateOutOfRange && isLookbackRestricted
        ? DATE_RANGE_ERROR
        : undefined;
    };
  };

  getDateValidation = startDate => {
    const validations = [
      V.required,
      V.validDate(SHORT_DATE_FORMAT),
      V.dateInThePast(SHORT_DATE_FORMAT),
    ];
    if (startDate) {
      validations.push(V.dateAfter(SHORT_DATE_FORMAT, startDate));
    }
    return validations;
  };

  handleCurrentPositionClicked = (e, isCurrentPosition) => {
    const {
      fieldName,
      dispatch,
      employer: { endDate },
    } = this.props;
    const { savedEndDate } = this.state;
    if (isCurrentPosition) {
      // if this is the current position, clear out the endDate field, to avoid
      // confusion for the user, and to make sure any endDate validation errors
      // don't show.
      // then, save the endDate they entered in case they change their mind
      dispatch(change('applyform', `${fieldName}.endDate`, ''));
      this.setState({ savedEndDate: endDate || '' });
    } else {
      // if this is NOT the current position, we don't want to show
      // the "do not contact" checkbox, so we'll set doNotContact to false
      dispatch(change('applyform', `${fieldName}.doNotContact`, false));
      if (savedEndDate) {
        // restore any saved endDate
        dispatch(change('applyform', `${fieldName}.endDate`, savedEndDate));
      }
    }
  };

  render() {
    const {
      fieldName,
      employer: { startDate, endDate, currentPosition },
      lookbackYears,
      presentLabel,
      isLookbackRestricted,
    } = this.props;
    const endDateOutOfRange = this.isEndDateOutOfRange(endDate, lookbackYears);

    return (
      <div className='employment-period'>
        <div className='row'>
          <div className='col-md-6'>
            <Field
              type='text'
              name={`${fieldName}.startDate`}
              label='labels.startDate'
              placeholder='placeholders.mmYY'
              component={Text}
              normalize={N.monthYear}
              validate={this.getDateValidation()}
            />
          </div>

          <div className='col-md-6'>
            <Field
              type='text'
              inputClass={endDateOutOfRange ? 'date-warning' : ''}
              name={`${fieldName}.endDate`}
              label='labels.endDate'
              placeholder={
                currentPosition
                  ? 'placeholders.currentPosition'
                  : 'placeholders.mmYY'
              }
              component={Text}
              normalize={N.monthYear}
              validate={
                !currentPosition
                  ? [
                      ...this.getDateValidation(startDate),
                      this.dateOutOfRangeValidation(
                        lookbackYears,
                        isLookbackRestricted,
                      ),
                    ]
                  : undefined
              }
              disabled={currentPosition}
            />
            <Field
              type='checkbox'
              name={`${fieldName}.currentPosition`}
              className='inline-checkbox top-inline'
              label={presentLabel}
              onChange={this.handleCurrentPositionClicked}
              component={Checkbox}
            />
          </div>
        </div>

        {endDateOutOfRange && (
          <div className='row'>
            <div className='col-md-12'>
              <p className='text-danger'>
                <i className='fa fa-exclamation-triangle' />
                &nbsp;
                <T
                  value='components.Employer.endDateOutOfRange'
                  lookbackYears={lookbackYears}
                />
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EmploymentPeriod;
