/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { groupClass } from './helper';
import Label from './Label';
import FeedbackLabel from './FeedbackLabel';
import Loading from '../Loading';
import { updateCreditCard, loadStripe } from '../../actions';

class StripeCreditCardElement extends Component {
  static propTypes = {
    card: PropTypes.shape({
      error: PropTypes.object,
    }),
    cardEl: PropTypes.object,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }),
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string,
    }),
    updateCreditCard: PropTypes.func.isRequired,
    loadStripe: PropTypes.func.isRequired,
    processing: PropTypes.bool,
    price: PropTypes.number,
  };

  static defaultProps = {
    processing: true,
  };

  constructor(props) {
    super(props);
    this.handleCardChange = this.handleCardChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    const { cardEl } = this.props;

    if (cardEl) {
      cardEl.mount('#payment-element');
      cardEl.on('change', this.handleCardChange);
    }
  }

  componentWillReceiveProps({ cardEl }) {
    const { props } = this;

    if (cardEl && !props.cardEl) {
      cardEl.mount('#payment-element');
      cardEl.on('change', this.handleCardChange);
      cardEl.on('blur', this.handleBlur);
    }
  }

  componentWillUnmount() {
    const { cardEl } = this.props;

    cardEl.unmount();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { props } = this;

    if (!props.cardEl) {
      const { price } = this.props;
      props.loadStripe(price);
    }
  }

  handleCardChange(cardEvent) {
    const { props } = this;
    props.updateCreditCard(cardEvent);
    props.input.onChange(cardEvent.complete);
  }

  handleBlur() {
    const { input } = this.props;
    input.onBlur();
  }

  render() {
    const {
      card: { error: cardError },
      meta: { touched, error, warning },
      processing,
    } = this.props;
    return (
      <div>
        <div className={groupClass(touched, error)}>
          <div className='cc-form'>
            <div className='form-row'>
              {processing && <Loading />}
              <div
                id='payment-element'
                className={processing ? '' : 'form-control'}
              />
              {(cardError || error) && touched && (
                <div id='payment-errors'>
                  <FeedbackLabel
                    {...{
                      touched,
                      error: (cardError && cardError.message) || error,
                      warning,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ creditCard: { cardEl, processing, ...rest } }) => ({
  cardEl,
  card: rest,
  processing,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      updateCreditCard,
      loadStripe,
    },
    dispatch,
  ),
});

const ConnectedStripeCreditCardElement = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeCreditCardElement);

export default ConnectedStripeCreditCardElement;
