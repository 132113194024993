/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { camelize } from 'humps';

import StartPage from 'pages/start/StartPage';
import ErrorPage from './ErrorPage';
import SubmitErrorPage from './SubmitErrorPage';

import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import MainContainer from './MainContainer';
import Page from './Page';
import Steps from './Steps';
import TestBanner from './TestBanner';
import Modal from './Modal';

import { PROTECT_PROFILE_MATCH_ERROR } from '../constants';
import { forwardToRedirect, trackAnalyticsEvent } from '../actions';
import IdentityVerificationRedirect from '../international/Pages/IdentityVerificationRedirect';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  getAnalyticsPageName,
  getEventNameForDomesticPageCompleted,
  getEventNameForDomesticPageViewed,
} from '../lib/analytics';

import FooterFixed from './FooterFixed';

class ApplyPage extends Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getInterruptHandler: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
    handleIncorrectInformation: PropTypes.func,
    handleNextPage: PropTypes.func.isRequired,
    handlePreviousPage: PropTypes.func.isRequired,
    navigation: PropTypes.object.isRequired,
    routeParams: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    trackAnalyticsEvent: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isContentLoaded: false,
    };
  }

  componentDidUpdate() {
    const { configuration, navigation } = this.props;
    const { isContentLoaded } = this.state;

    const {
      configuration: { pages },
    } = configuration;
    const pageIdx = navigation.currentPage - 1;

    if (pages) {
      const page = pages[pageIdx] || pages[0];

      if (!isContentLoaded) {
        this.trackStepViewedEvent(page.name);
      }
    }
  }

  trackStepViewedEvent = pageName => {
    if (
      pageName.toLowerCase() !== 'welcome' &&
      pageName.toLowerCase() !== 'start'
    ) {
      return;
    }

    this.setState({ isContentLoaded: true });
    this.trackStepEvent(getEventNameForDomesticPageViewed(pageName));
  };

  trackStepCompletedEvent = pageName => {
    this.trackStepEvent(getEventNameForDomesticPageCompleted(pageName));
  };

  trackValidationErrorTriggered = pageName => {
    const { trackAnalyticsEvent: trackAnalyticsEventAction } = this.props;
    const eventProps = {};

    eventProps[ANALYTICS_PROPERTIES.PAGE_NAME] = getAnalyticsPageName(pageName);

    trackAnalyticsEventAction(
      ANALYTICS_EVENTS.DOMESTIC_APPLY_FLOW_VALIDATION_ERROR_TRIGGERED,
      eventProps,
    );
  };

  trackStepEvent = eventString => {
    const { trackAnalyticsEvent: trackAnalyticsEventAction } = this.props;

    trackAnalyticsEventAction(eventString);
  };

  render() {
    const {
      configuration,
      form: { applyform },
      getInterruptHandler,
      handleFormSubmit,
      handleNextPage,
      handlePreviousPage,
      handleIncorrectInformation,
      navigation,
      isSubmitting,
      routeParams: { isTest },
    } = this.props;

    if (!configuration.validRedirect) {
      return (
        <MainContainer>
          <ErrorPage
            error={{ message: 'Invalid Redirect URL' }}
            trackAnalyticsEvent={trackAnalyticsEvent}
          />
        </MainContainer>
      );
    }

    const { error } = configuration;
    if (error) {
      return (
        <MainContainer>
          <ErrorPage error={error} trackAnalyticsEvent={trackAnalyticsEvent} />
        </MainContainer>
      );
    }
    const { fetching, refreshing } = configuration;
    if (fetching && !refreshing) {
      return (
        <MainContainer>
          <Loading />
        </MainContainer>
      );
    }

    const {
      configuration: {
        account: { name },
        candidate: { hasSsn },
        layout: { footer, header, hideSteps },
        pages,
        package: { applicantPay, price, protectedInvitationFlow },
      },
    } = configuration;
    const pageIdx = navigation.currentPage - 1;
    const page = pages[pageIdx] || pages[0];

    const accountName = name;
    const { syncErrors, submitErrors, submitFailed } = applyform;
    const hasSyncError =
      syncErrors !== null && syncErrors !== undefined && submitFailed;
    if (
      submitErrors &&
      submitErrors.candidate?.candidate?.includes(PROTECT_PROFILE_MATCH_ERROR)
    ) {
      return (
        <div>
          {header && <Header components={header} />}
          <MainContainer>
            <SubmitErrorPage
              error={camelize(PROTECT_PROFILE_MATCH_ERROR)}
              accountName={accountName}
            />
          </MainContainer>
          {footer && <Footer currentPageName={page.name} components={footer} />}
        </div>
      );
    }

    const nextStep = (...args) => {
      this.trackStepCompletedEvent(page.name);

      if (navigation.currentPage < pages.length) {
        return handleNextPage(...args);
      }

      return handleFormSubmit(...args);
    };
    const handleSubmit = (...args) => {
      return getInterruptHandler(nextStep)(...args);
    };
    const partialSsnEntered =
      configuration.partialSsn && configuration.partialSsn.length === 4;
    const partialSsnRequired = hasSsn;
    const showNav = !(page.props && page.props.hideNav);
    const showSteps = !hideSteps && showNav && pages.length > 1;
    const handleSubmitFail = () => {
      this.trackValidationErrorTriggered(page.name);
    };

    const navigationProps = {
      ...navigation,
      applicantPay,
      protectedInvitationFlow,
      handleIncorrectInformation,
      handleSubmit,
      handlePreviousPage,
      page,
      price,
      partialSsnEntered,
      partialSsnRequired,
      showNav,
      handleSubmitFail,
      hasSyncError,
    };

    if (page.name === 'start') {
      const pageProps = page?.components[0]?.props;
      const expirationDate = pageProps?.expirationDate;
      const requirements = pageProps?.requirements || ['ssn'];

      return (
        <StartPage
          accountName={name}
          expiresAt={expirationDate}
          footer={footer || []}
          navigation={navigationProps}
          header={header}
          isTest={isTest}
          page={page}
          pageIdx={pageIdx}
          pages={pages}
          requirements={requirements}
          additionalOptions={pageProps?.additionalOptions}
          showSteps={showSteps}
        />
      );
    }

    return (
      <div className='mastodon-grid bx--grid'>
        {isTest && <TestBanner />}
        {header && <Header components={header} />}

        <MainContainer>
          {showSteps && (
            <Steps steps={pages.map(step => step.name)} currentStep={pageIdx} />
          )}
          {navigation.identityVerificationRedirect ? (
            <div id='mastodon'>
              <IdentityVerificationRedirect
                key='idRedirect'
                className='identity-verification-redirect'
                onSubmit={() =>
                  forwardToRedirect(navigation.identityVerificationRedirect)
                }
                isSubmitting={isSubmitting}
              />
            </div>
          ) : (
            <Page page={page} navigation={navigationProps} />
          )}
        </MainContainer>
        {footer && <Footer currentPageName={page.name} components={footer} />}
        <FooterFixed navigation={navigationProps} />
        <Modal />
      </div>
    );
  }
}

export default ApplyPage;
