/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { connect } from 'react-redux';
import { Checkbox, ReCaptchaV2, StripeCreditCardElement } from './fields';

import { required, requiredCheckbox, validCC } from '../lib/validations';

import HTMLText from './HTMLText';
import Panel from './Panel';

const PaymentForm = ({
  content,
  price,
  finalPage,
  accountId,
  partnerAccountUriName,
}) => {
  return (
    <Panel title='components.PaymentForm.title' icon='fa-credit-card'>
      <div className='alert alert-warning'>
        <HTMLText content={content} />
      </div>
      <Field
        name='isValidCC'
        component={StripeCreditCardElement}
        validate={validCC}
        price={parseFloat(price)}
      />

      <div className='col-md-12'>
        <Field
          type='checkbox'
          name='applicantPay'
          label='components.PaymentForm.label'
          labelOptions={{ price: parseFloat(price / 100) }}
          component={Checkbox}
          validate={[requiredCheckbox]}
          labelFeedback
          boldLabel
        />
      </div>
      {finalPage && (
        <div className='col-md-6 mb-0'>
          <Field
            name='recaptchaCode'
            component={ReCaptchaV2}
            validate={required}
          />
        </div>
      )}
    </Panel>
  );
};

PaymentForm.propTypes = {
  content: PropTypes.string.isRequired,
  finalPage: PropTypes.bool,
  price: PropTypes.number,
  accountId: PropTypes.string,
  partnerAccountUriName: PropTypes.string,
};

export default connect(
  state => ({
    accountId: state?.configuration?.configuration?.account?.id,
    partnerAccountUriName: state?.configuration?.routeParams?.company,
  }),
  dispatch => ({
    dispatch,
  }),
)(PaymentForm);
