/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';
import BaseLayout from 'layouts/BaseLayout';
import Panel from '../Panel';
import ParsWelcome from '../pars/ParsWelcome';
import {
  getRequiredExams,
  getSelectedExams,
  getParsPackagePurpose,
  getParsPackageModality,
} from '../../actions';

const Well = styled.div`
  background-color: #f2f5f7;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
`;

const WellRow = styled.div`
  @media screen and (min-width: 768px) {
    font-size: 21px;
  }
`;

class OneEightHundredInstructions extends Component {
  static propTypes = {
    exams: PropTypes.string,
    purpose: PropTypes.string,
    accountName: PropTypes.string,
    eScreenAccount: PropTypes.string,
    donorName: PropTypes.string,
    donorPhoneNumber: PropTypes.string,
    reporterName: PropTypes.string,
    custodyControlFormNumber: PropTypes.string,
    modality: PropTypes.string,
  };

  render() {
    const {
      exams,
      purpose,
      accountName,
      eScreenAccount,
      donorName,
      donorPhoneNumber,
      reporterName,
      custodyControlFormNumber,
      modality,
    } = this.props;
    return (
      <BaseLayout header footer={[]} intro={null}>
        <ParsWelcome />
        <Panel>
          <h1>
            <T value='components.OneEightHundredInstructions.title' />
          </h1>
          <p>
            <T
              value='components.OneEightHundredInstructions.body'
              exams={exams}
              purpose={purpose}
              dangerousHTML
            />
          </p>
          <a
            className='btn btn-primary'
            type='button'
            style={{ width: '100%' }}
            href='tel:+1800-881-0722'
          >
            <i className='fa fa-phone' />
            &nbsp;
            <T value='components.OneEightHundredInstructions.callEScreen' />
          </a>

          <Well>
            <WellRow>
              <b>Account Name:</b> {accountName}
            </WellRow>
            <WellRow>
              <b>eScreen Account:</b> {eScreenAccount}
            </WellRow>
            <WellRow>
              <b>Reason for test:</b> {purpose}
            </WellRow>
            <WellRow>
              <b>Test type:</b> {exams}
            </WellRow>
            <WellRow>
              <b>Modality:</b> {modality}
            </WellRow>
            <WellRow>
              <b>Custody & control form:</b> {custodyControlFormNumber}
            </WellRow>
            <WellRow>
              <b>Reporter name:</b> {reporterName}
            </WellRow>
            <WellRow>
              <b>Donor name:</b> {donorName}
            </WellRow>
            <WellRow>
              <b>Donor phone number:</b> {donorPhoneNumber}
            </WellRow>
          </Well>
          <T
            value='components.OneEightHundredInstructions.extraInformation'
            dangerousHTML
          />
        </Panel>
      </BaseLayout>
    );
  }
}

const ConnectedOneEightHundredInstructions = connect((state, props) => {
  const exams = getRequiredExams(state).concat(getSelectedExams(state));
  const purpose = getParsPackagePurpose(state);
  const examLabels = [
    ...new Set(
      exams.map(exam => {
        // If the exam has an escreen integration, use the panelId
        const escreenIntegration = exam.examVendorIntegrations.find(
          obj => obj.integration === 'escreen',
        );

        const panelId = escreenIntegration ? escreenIntegration.panelId : null;

        return panelId ? `${panelId} (${exam.label})` : exam.label;
      }),
    ),
  ].join(', ');
  const accountName = state.configuration.configuration.account.name;
  const eScreenAccount =
    state.configuration.configuration.account.escreenAccountNumber ||
    'Account missing number';
  const applyForm = state.form.applyform.values;
  const donorName = `${applyForm.firstName} ${
    applyForm.noMiddleName ? '' : `${applyForm.middleName} `
  }${applyForm.lastName}`;
  return {
    exams: examLabels,
    purpose,
    accountName,
    eScreenAccount,
    donorName,
    donorPhoneNumber: applyForm.phone || applyForm.reporterPhone,
    reporterName: applyForm.reporterFullName,
    custodyControlFormNumber: applyForm.custodyControlFormNumber,
    modality: getParsPackageModality(state),
    ...props,
  };
})(OneEightHundredInstructions);

export default ConnectedOneEightHundredInstructions;
