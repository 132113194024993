/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Field, formValueSelector } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { Checkbox, DecoratedFieldArray } from '../fields';
import Panel from '../Panel';
import * as V from '../../lib/validations';
import {
  argyleDebugLog,
  hasUnallocatedPeriods,
  useArgyleFlowId,
  hasInsufficientLookback,
  useLookbackRestriction,
} from '../../lib/employment/utils/employmentUtils';
import ArgyleReviewModal from './ArgyleReviewModal';
import ArgyleLinkErrorModal from './ArgyleLinkErrorModal';
import Employers from './Employers';
import openArgyle from '../../lib/employment/Argyle';
import '../../css/employment.scss';
import {
  addArgyleConnectedAccountId,
  onArgyleLinkClose,
  setArgyleState,
} from '../../actions/employment/employmentActions';
import { trackAnalyticsEvent } from '../../actions/analytics';

const instructionsCopy = (lookbackYears, maximumAllowedEmployers) => {
  const yearsKey = lookbackYears === 1 ? 'oneYear' : 'manyYears';

  const employersKey = (() => {
    switch (maximumAllowedEmployers) {
      case null:
        return 'allEmployers';
      case 1:
        return 'oneEmployer';
      default:
        return 'manyEmployers';
    }
  })();
  const copyKey = `components.EmploymentForm.instructions.${yearsKey}.${employersKey}`;

  return I18n.t(copyKey, { lookbackYears, maximumAllowedEmployers });
};

const InfoBox = ({ maximumAllowedEmployers, lookbackYears }) => (
  <div>
    <p>
      <strong>
        {instructionsCopy(lookbackYears, maximumAllowedEmployers)}
      </strong>
    </p>
  </div>
);

InfoBox.propTypes = {
  maximumAllowedEmployers: PropTypes.number,
  lookbackYears: PropTypes.number,
};

// remove_after_lookback_rollout
const ConfirmFullLookbackCheckbox = ({ lookbackYears }) => (
  <Panel>
    <Field
      type='checkbox'
      name='employmentConfirmFullLookback'
      label='labels.employmentConfirmFullLookback'
      labelOptions={{ lookbackYears }}
      className='no-bottom-margin'
      component={Checkbox}
      validate={[V.requiredCheckbox]}
      boldLabel
    />
  </Panel>
);

// remove_after_lookback_rollout
ConfirmFullLookbackCheckbox.propTypes = {
  lookbackYears: PropTypes.number,
};

const FullLookbackRequiredErrorPanel = ({ lookbackYears }) => {
  return (
    <div className='card clearfix mb-3'>
      <div className='card-block'>
        <div>
          {I18n.t('components.EmploymentForm.employmentLookbackRequired', {
            lookbackYears,
          })}
        </div>
        <Field
          name='employer-required-blocker'
          component='input'
          type='hidden'
          validate={V.required}
        />
      </div>
    </div>
  );
};

FullLookbackRequiredErrorPanel.propTypes = {
  lookbackYears: PropTypes.number,
};

const EmploymentHistoryForm = ({
  dispatch,
  employers,
  managerRequired,
  noEmploymentHistory,
  contractTypeRequired,
  maximumAllowedEmployers,
  lookbackYears,
  isDot,
  documentCollection,
  accountId,
  accountName,
  argyleEnabled,
  argyleCurrentState,
  argyleConnectedAccountIds,
  argyleAccountEmployments,
}) => {
  const flowId = useArgyleFlowId(maximumAllowedEmployers);
  const isLookbackRestricted = useLookbackRestriction(accountId);

  const doOpenArgyle = () => {
    if (argyleCurrentState !== 'uninitialized') {
      argyleDebugLog('Preventing redundant initialization of Argyle Link');
      return;
    }

    dispatch(setArgyleState('link-init'));
    openArgyle({
      accountName,
      addArgyleConnectedAccountId: argyleAccountId =>
        dispatch(addArgyleConnectedAccountId(argyleAccountId)),
      onArgyleLinkClose: () => {
        dispatch(onArgyleLinkClose());
      },
      setArgyleState: newArgyleState =>
        dispatch(setArgyleState(newArgyleState)),
      trackArgyleAnalyticsEvent: (eventTitle, eventProperties = {}) => {
        dispatch(trackAnalyticsEvent(eventTitle, eventProperties));
      },
      maximumAllowedEmployers,
      flowId,
    });
  };

  // Missing jobs or gaps within lookback period due to "Provided employment must meet minimum lookback requirement"
  const insufficientLookbackHistory = hasInsufficientLookback(
    lookbackYears,
    maximumAllowedEmployers,
    employers,
  );

  // remove_after_lookback_rollout
  const unallocatedPeriodExist = hasUnallocatedPeriods(
    lookbackYears,
    employers,
  );

  // remove_after_lookback_rollout
  const shouldEnforceLookbackRestriction =
    insufficientLookbackHistory && isLookbackRestricted;

  // remove_after_lookback_rollout
  const shouldShowStableView = unallocatedPeriodExist && !isLookbackRestricted;

  return (
    <div>
      {['polling', 'reviewing', 'polling-timeout'].includes(
        argyleCurrentState,
      ) && (
        <ArgyleReviewModal
          argyleCurrentState={argyleCurrentState}
          argyleConnectedAccountIds={argyleConnectedAccountIds}
          argyleAccountEmployments={argyleAccountEmployments}
          maximumAllowedEmployers={maximumAllowedEmployers}
          lookbackYears={lookbackYears}
          employmentsOnForm={employers}
          companyName={accountName}
          onClose={() => dispatch(setArgyleState('uninitialized'))}
          trackArgyleAnalyticsEvent={(eventTitle, eventProperties = {}) =>
            dispatch(trackAnalyticsEvent(eventTitle, eventProperties))
          }
        />
      )}

      {argyleCurrentState === 'link-error' && (
        // todo: adl - render an error modal/dialog or something?
        // we can reset the argyleCurrentState back to 'uninitialized' after the user acknowledges the dialog?
        // (maybe there's a better way to handle this?)
        <ArgyleLinkErrorModal
          onClose={() => dispatch(setArgyleState('uninitialized'))}
        />
      )}

      {argyleCurrentState === 'link-init' && (
        // this modal renders simply to prevent the user from clicking on anything while we initialize Argyle
        // Link (primarily so they can't initialize it twice)
        <M.ComposedModal
          open
          onClose={() => undefined}
          preventCloseOnClickOutside
          size='xs'
          id='mastodon'
        >
          <M.ModalBody id='body-content'>
            <M.ProgressBar processing />
          </M.ModalBody>
        </M.ComposedModal>
      )}

      <Panel title='components.EmploymentForm.title' icon='fa-briefcase'>
        <InfoBox
          maximumAllowedEmployers={maximumAllowedEmployers}
          lookbackYears={lookbackYears}
        />
        {!noEmploymentHistory && (
          <DecoratedFieldArray
            {...{
              dispatch,
              employers,
              managerRequired,
              noEmploymentHistory,
              contractTypeRequired,
              maximumAllowedEmployers,
              lookbackYears,
              isDot,
              documentCollection,
              argyleEnabled,
              argyleAccountEmployments,
              openArgyle: doOpenArgyle,
              insufficientLookbackHistory,
              isLookbackRestricted,
            }}
            name='employment'
            component={Employers}
          />
        )}
      </Panel>

      {shouldEnforceLookbackRestriction ? (
        // If `shouldEnforceLookbackRestriction` is true, it applies new rules and displays
        // `FullLookbackRequiredErrorPanel`, indicating that new lookback restrictions
        <FullLookbackRequiredErrorPanel {...{ lookbackYears }} />
      ) : (
        // Otherwise, it continues with the stable view and displays `ConfirmFullLookbackCheckbox`
        // remove_after_lookback_rollout
        shouldShowStableView && (
          <ConfirmFullLookbackCheckbox {...{ lookbackYears }} />
        )
      )}
    </div>
  );
};

EmploymentHistoryForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employers: PropTypes.arrayOf(PropTypes.object),
  managerRequired: PropTypes.bool,
  noEmploymentHistory: PropTypes.bool,
  contractTypeRequired: PropTypes.bool,
  maximumAllowedEmployers: PropTypes.number,
  lookbackYears: PropTypes.number,
  isDot: PropTypes.bool,
  documentCollection: PropTypes.bool,
  accountId: PropTypes.string,
  accountName: PropTypes.string,
  argyleEnabled: PropTypes.bool,
  // todo: adl - should we make an enum to encapsulate the valid values for this?
  argyleCurrentState: PropTypes.oneOf([
    'uninitialized',
    'link-init',
    'linking',
    'link-error',
    'polling',
    'polling-timeout',
    'reviewing',
  ]),
  argyleConnectedAccountIds: PropTypes.arrayOf(PropTypes.string),
  argyleAccountEmployments: PropTypes.arrayOf(
    PropTypes.shape({
      argyleAccountId: PropTypes.string,
      argyleEmployments: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
};

const selector = formValueSelector('applyform');

// remove_after_lookback_rollout_accountId
const mapStateToProps = state => {
  return {
    noEmploymentHistory: selector(state, 'noEmploymentHistory'),
    employers: selector(state, 'employment'),
    accountId: state.configuration.configuration.account.id,
    accountName: state.configuration.configuration.account.name,
    argyleCurrentState: state.employmentScreening.argyleCurrentState,
    argyleConnectedAccountIds:
      state.employmentScreening.argyleConnectedAccountIds,
    argyleAccountEmployments:
      state.employmentScreening.argyleAccountEmployments,
  };
};

export default connect(mapStateToProps)(EmploymentHistoryForm);
